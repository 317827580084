// @generated

import { z } from 'zod';

export type OperatorSignupBodyDto = z.infer<typeof OperatorSignupBodyDto>;
export const OperatorSignupBodyDto = z.object({
	email: z.string(),
	password: z.string(),
	firstName: z.string(),
	lastName: z.string(),
});

export type AuthOperatorDto = z.infer<typeof AuthOperatorDto>;
export const AuthOperatorDto = z.object({
	id: z.string(),
	firstName: z.string(),
	lastName: z.string(),
	email: z.string(),
	troonAccessUpsellInfo: z.object({
		subscriptionsSold: z.number(),
		commission: z.object({
			value: z.string(),
			code: z.union([
				z.literal('AED'),
				z.literal('AUD'),
				z.literal('AZN'),
				z.literal('BGN'),
				z.literal('BHD'),
				z.literal('BSD'),
				z.literal('CAD'),
				z.literal('CHF'),
				z.literal('CRC'),
				z.literal('CZK'),
				z.literal('EGP'),
				z.literal('EUR'),
				z.literal('GBP'),
				z.literal('HNL'),
				z.literal('INR'),
				z.literal('JOD'),
				z.literal('KHR'),
				z.literal('KRW'),
				z.literal('LKR'),
				z.literal('MAD'),
				z.literal('MXN'),
				z.literal('MYR'),
				z.literal('NOK'),
				z.literal('OMR'),
				z.literal('PAB'),
				z.literal('SAR'),
				z.literal('TWD'),
				z.literal('USD'),
			]),
			cents: z.number(),
			displayValue: z.string(),
		}),
		bonus: z.object({
			value: z.string(),
			code: z.union([
				z.literal('AED'),
				z.literal('AUD'),
				z.literal('AZN'),
				z.literal('BGN'),
				z.literal('BHD'),
				z.literal('BSD'),
				z.literal('CAD'),
				z.literal('CHF'),
				z.literal('CRC'),
				z.literal('CZK'),
				z.literal('EGP'),
				z.literal('EUR'),
				z.literal('GBP'),
				z.literal('HNL'),
				z.literal('INR'),
				z.literal('JOD'),
				z.literal('KHR'),
				z.literal('KRW'),
				z.literal('LKR'),
				z.literal('MAD'),
				z.literal('MXN'),
				z.literal('MYR'),
				z.literal('NOK'),
				z.literal('OMR'),
				z.literal('PAB'),
				z.literal('SAR'),
				z.literal('TWD'),
				z.literal('USD'),
			]),
			cents: z.number(),
			displayValue: z.string(),
		}),
		subscriptionsForBonus: z.number(),
		subscriptionsToNextBonus: z.number(),
		earningsPerSubscription: z.object({
			value: z.string(),
			code: z.union([
				z.literal('AED'),
				z.literal('AUD'),
				z.literal('AZN'),
				z.literal('BGN'),
				z.literal('BHD'),
				z.literal('BSD'),
				z.literal('CAD'),
				z.literal('CHF'),
				z.literal('CRC'),
				z.literal('CZK'),
				z.literal('EGP'),
				z.literal('EUR'),
				z.literal('GBP'),
				z.literal('HNL'),
				z.literal('INR'),
				z.literal('JOD'),
				z.literal('KHR'),
				z.literal('KRW'),
				z.literal('LKR'),
				z.literal('MAD'),
				z.literal('MXN'),
				z.literal('MYR'),
				z.literal('NOK'),
				z.literal('OMR'),
				z.literal('PAB'),
				z.literal('SAR'),
				z.literal('TWD'),
				z.literal('USD'),
			]),
			cents: z.number(),
			displayValue: z.string(),
		}),
	}),
});

export type HTTPErrorDto = z.infer<typeof HTTPErrorDto>;
export const HTTPErrorDto = z.object({
	displayMessage: z.string(),
	fields: z
		.union([
			z.array(
				z.object({
					field: z.string(),
					displayMessage: z.string(),
				}),
			),
			z.undefined(),
		])
		.optional(),
});

export type OperatorLoginBodyDto = z.infer<typeof OperatorLoginBodyDto>;
export const OperatorLoginBodyDto = z.object({
	email: z.string(),
	password: z.string(),
});

export type OperatorLoginResponseDto = z.infer<typeof OperatorLoginResponseDto>;
export const OperatorLoginResponseDto = z.object({
	accessToken: z.string(),
	refreshToken: z.string(),
	operator: z.object({
		id: z.string(),
		firstName: z.string(),
		lastName: z.string(),
		email: z.string(),
		troonAccessUpsellInfo: z.object({
			subscriptionsSold: z.number(),
			commission: z.object({
				value: z.string(),
				code: z.union([
					z.literal('AED'),
					z.literal('AUD'),
					z.literal('AZN'),
					z.literal('BGN'),
					z.literal('BHD'),
					z.literal('BSD'),
					z.literal('CAD'),
					z.literal('CHF'),
					z.literal('CRC'),
					z.literal('CZK'),
					z.literal('EGP'),
					z.literal('EUR'),
					z.literal('GBP'),
					z.literal('HNL'),
					z.literal('INR'),
					z.literal('JOD'),
					z.literal('KHR'),
					z.literal('KRW'),
					z.literal('LKR'),
					z.literal('MAD'),
					z.literal('MXN'),
					z.literal('MYR'),
					z.literal('NOK'),
					z.literal('OMR'),
					z.literal('PAB'),
					z.literal('SAR'),
					z.literal('TWD'),
					z.literal('USD'),
				]),
				cents: z.number(),
				displayValue: z.string(),
			}),
			bonus: z.object({
				value: z.string(),
				code: z.union([
					z.literal('AED'),
					z.literal('AUD'),
					z.literal('AZN'),
					z.literal('BGN'),
					z.literal('BHD'),
					z.literal('BSD'),
					z.literal('CAD'),
					z.literal('CHF'),
					z.literal('CRC'),
					z.literal('CZK'),
					z.literal('EGP'),
					z.literal('EUR'),
					z.literal('GBP'),
					z.literal('HNL'),
					z.literal('INR'),
					z.literal('JOD'),
					z.literal('KHR'),
					z.literal('KRW'),
					z.literal('LKR'),
					z.literal('MAD'),
					z.literal('MXN'),
					z.literal('MYR'),
					z.literal('NOK'),
					z.literal('OMR'),
					z.literal('PAB'),
					z.literal('SAR'),
					z.literal('TWD'),
					z.literal('USD'),
				]),
				cents: z.number(),
				displayValue: z.string(),
			}),
			subscriptionsForBonus: z.number(),
			subscriptionsToNextBonus: z.number(),
			earningsPerSubscription: z.object({
				value: z.string(),
				code: z.union([
					z.literal('AED'),
					z.literal('AUD'),
					z.literal('AZN'),
					z.literal('BGN'),
					z.literal('BHD'),
					z.literal('BSD'),
					z.literal('CAD'),
					z.literal('CHF'),
					z.literal('CRC'),
					z.literal('CZK'),
					z.literal('EGP'),
					z.literal('EUR'),
					z.literal('GBP'),
					z.literal('HNL'),
					z.literal('INR'),
					z.literal('JOD'),
					z.literal('KHR'),
					z.literal('KRW'),
					z.literal('LKR'),
					z.literal('MAD'),
					z.literal('MXN'),
					z.literal('MYR'),
					z.literal('NOK'),
					z.literal('OMR'),
					z.literal('PAB'),
					z.literal('SAR'),
					z.literal('TWD'),
					z.literal('USD'),
				]),
				cents: z.number(),
				displayValue: z.string(),
			}),
		}),
	}),
});

export type SuccessResponseDto = z.infer<typeof SuccessResponseDto>;
export const SuccessResponseDto = z.object({
	success: z.boolean(),
});

export type ForgotPasswordBodyDto = z.infer<typeof ForgotPasswordBodyDto>;
export const ForgotPasswordBodyDto = z.object({
	email: z.string(),
});

export type ForgotPasswordDto = z.infer<typeof ForgotPasswordDto>;
export const ForgotPasswordDto = z.object({
	email: z.string(),
	expirationInMinutes: z.number(),
});

export type ResetPasswordBodyDto = z.infer<typeof ResetPasswordBodyDto>;
export const ResetPasswordBodyDto = z.object({
	email: z.string(),
	password: z.string(),
	confirmationCode: z.string(),
});

export type ChangePasswordDto = z.infer<typeof ChangePasswordDto>;
export const ChangePasswordDto = z.object({
	oldPassword: z.string(),
	newPassword: z.string(),
});

export type UpdateOperatorBodyDto = z.infer<typeof UpdateOperatorBodyDto>;
export const UpdateOperatorBodyDto = z.object({
	firstName: z.string().optional(),
	lastName: z.string().optional(),
});

export type FullOperatorInviteDto = z.infer<typeof FullOperatorInviteDto>;
export const FullOperatorInviteDto = z.object({
	id: z.string(),
	email: z.string(),
	createdAt: z.string(),
	expiresAt: z.string(),
	facility: z.object({
		id: z.string(),
		name: z.string(),
		slug: z.string(),
		logo: z.union([z.string(), z.null(), z.undefined()]).optional(),
		timezone: z.string(),
		currencyCode: z.union([
			z.literal('AED'),
			z.literal('AUD'),
			z.literal('AZN'),
			z.literal('BGN'),
			z.literal('BHD'),
			z.literal('BSD'),
			z.literal('CAD'),
			z.literal('CHF'),
			z.literal('CRC'),
			z.literal('CZK'),
			z.literal('EGP'),
			z.literal('EUR'),
			z.literal('GBP'),
			z.literal('HNL'),
			z.literal('INR'),
			z.literal('JOD'),
			z.literal('KHR'),
			z.literal('KRW'),
			z.literal('LKR'),
			z.literal('MAD'),
			z.literal('MXN'),
			z.literal('MYR'),
			z.literal('NOK'),
			z.literal('OMR'),
			z.literal('PAB'),
			z.literal('SAR'),
			z.literal('TWD'),
			z.literal('USD'),
		]),
		isAvailable: z.boolean(),
		isBookable: z.boolean(),
		supportsTroonAccess: z.boolean(),
		supportsTroonAccessAssociateCommisions: z.boolean(),
		supportsTroonRewards: z.boolean(),
		supportsTroonAdvantage: z.boolean(),
	}),
	role: z.union([z.literal('ADMIN'), z.literal('MEMBER')]),
	state: z.union([z.literal('PENDING'), z.literal('ACCEPTED'), z.literal('REJECTED'), z.literal('CANCELLED')]),
	inviter: z.object({
		id: z.string(),
		firstName: z.string(),
		lastName: z.string(),
	}),
});

export type OperatorRewardsUserListDto = z.infer<typeof OperatorRewardsUserListDto>;
export const OperatorRewardsUserListDto = z.object({
	users: z.array(
		z.object({
			rewardsId: z.string(),
			email: z.string(),
			firstName: z.union([z.string(), z.null(), z.undefined()]).optional(),
			lastName: z.union([z.string(), z.null(), z.undefined()]).optional(),
			troonAccessProductType: z
				.union([z.literal('TROON_ACCESS'), z.literal('TROON_ACCESS_PLUS'), z.null(), z.undefined()])
				.optional(),
			rewardsLevel: z.union([z.literal('MEMBER'), z.literal('SILVER'), z.literal('GOLD'), z.literal('PLATINUM')]),
			city: z.union([z.string(), z.null(), z.undefined()]).optional(),
			state: z.union([z.string(), z.null(), z.undefined()]).optional(),
			country: z.union([z.string(), z.null(), z.undefined()]).optional(),
			isDisabled: z.boolean(),
		}),
	),
});

export type OperatorRewardsUserProfileDto = z.infer<typeof OperatorRewardsUserProfileDto>;
export const OperatorRewardsUserProfileDto = z.object({
	rewardsId: z.string(),
	accessProductType: z
		.union([z.literal('TROON_ACCESS'), z.literal('TROON_ACCESS_PLUS'), z.null(), z.undefined()])
		.optional(),
	firstName: z.union([z.string(), z.null(), z.undefined()]).optional(),
	lastName: z.union([z.string(), z.null(), z.undefined()]).optional(),
	email: z.union([z.string(), z.null(), z.undefined()]).optional(),
	phoneNumber: z.union([z.string(), z.null(), z.undefined()]).optional(),
	address: z.object({
		street: z.union([z.string(), z.null()]),
		street2: z.union([z.string(), z.null()]),
		city: z.union([z.string(), z.null()]),
		state: z.union([z.string(), z.null()]),
		country: z.union([z.string(), z.null()]),
		postalCode: z.union([z.string(), z.null()]),
	}),
	signupDate: z.string(),
	points: z.object({
		availablePoints: z.number(),
		rewardsLevel: z.union([z.literal('MEMBER'), z.literal('SILVER'), z.literal('GOLD'), z.literal('PLATINUM')]),
		ytdPoints: z.array(
			z.object({
				points: z.number(),
				year: z.number(),
			}),
		),
	}),
	programs: z.array(
		z.object({
			id: z.string(),
			name: z.string(),
		}),
	),
	availableGuestPasses: z.union([z.number(), z.null(), z.undefined()]).optional(),
	unlimitedGuestPasses: z.boolean(),
	accessSubscription: z
		.union([
			z.object({
				id: z.string(),
				product: z.object({
					name: z.string(),
					type: z.union([z.literal('TROON_ACCESS'), z.literal('TROON_ACCESS_PLUS')]),
				}),
				startDate: z.object({
					epoch: z.number(),
					tz: z.string(),
				}),
				endDate: z.object({
					epoch: z.number(),
					tz: z.string(),
				}),
				status: z.union([
					z.literal('PENDING'),
					z.literal('ACTIVE'),
					z.literal('CANCELLED'),
					z.literal('EXPIRED'),
					z.literal('REFUNDED'),
				]),
			}),
			z.null(),
			z.undefined(),
		])
		.optional(),
	potentialAccessSavings: z
		.union([
			z.object({
				totalAccessDiscount: z.object({
					value: z.string(),
					code: z.union([
						z.literal('AED'),
						z.literal('AUD'),
						z.literal('AZN'),
						z.literal('BGN'),
						z.literal('BHD'),
						z.literal('BSD'),
						z.literal('CAD'),
						z.literal('CHF'),
						z.literal('CRC'),
						z.literal('CZK'),
						z.literal('EGP'),
						z.literal('EUR'),
						z.literal('GBP'),
						z.literal('HNL'),
						z.literal('INR'),
						z.literal('JOD'),
						z.literal('KHR'),
						z.literal('KRW'),
						z.literal('LKR'),
						z.literal('MAD'),
						z.literal('MXN'),
						z.literal('MYR'),
						z.literal('NOK'),
						z.literal('OMR'),
						z.literal('PAB'),
						z.literal('SAR'),
						z.literal('TWD'),
						z.literal('USD'),
					]),
					cents: z.number(),
					displayValue: z.string(),
				}),
				totalAccessPlusDiscount: z.object({
					value: z.string(),
					code: z.union([
						z.literal('AED'),
						z.literal('AUD'),
						z.literal('AZN'),
						z.literal('BGN'),
						z.literal('BHD'),
						z.literal('BSD'),
						z.literal('CAD'),
						z.literal('CHF'),
						z.literal('CRC'),
						z.literal('CZK'),
						z.literal('EGP'),
						z.literal('EUR'),
						z.literal('GBP'),
						z.literal('HNL'),
						z.literal('INR'),
						z.literal('JOD'),
						z.literal('KHR'),
						z.literal('KRW'),
						z.literal('LKR'),
						z.literal('MAD'),
						z.literal('MXN'),
						z.literal('MYR'),
						z.literal('NOK'),
						z.literal('OMR'),
						z.literal('PAB'),
						z.literal('SAR'),
						z.literal('TWD'),
						z.literal('USD'),
					]),
					cents: z.number(),
					displayValue: z.string(),
				}),
				reservations: z.array(
					z.object({
						savings: z.object({
							accessDiscount: z.object({
								value: z.string(),
								code: z.union([
									z.literal('AED'),
									z.literal('AUD'),
									z.literal('AZN'),
									z.literal('BGN'),
									z.literal('BHD'),
									z.literal('BSD'),
									z.literal('CAD'),
									z.literal('CHF'),
									z.literal('CRC'),
									z.literal('CZK'),
									z.literal('EGP'),
									z.literal('EUR'),
									z.literal('GBP'),
									z.literal('HNL'),
									z.literal('INR'),
									z.literal('JOD'),
									z.literal('KHR'),
									z.literal('KRW'),
									z.literal('LKR'),
									z.literal('MAD'),
									z.literal('MXN'),
									z.literal('MYR'),
									z.literal('NOK'),
									z.literal('OMR'),
									z.literal('PAB'),
									z.literal('SAR'),
									z.literal('TWD'),
									z.literal('USD'),
								]),
								cents: z.number(),
								displayValue: z.string(),
							}),
							accessPlusDiscount: z.object({
								value: z.string(),
								code: z.union([
									z.literal('AED'),
									z.literal('AUD'),
									z.literal('AZN'),
									z.literal('BGN'),
									z.literal('BHD'),
									z.literal('BSD'),
									z.literal('CAD'),
									z.literal('CHF'),
									z.literal('CRC'),
									z.literal('CZK'),
									z.literal('EGP'),
									z.literal('EUR'),
									z.literal('GBP'),
									z.literal('HNL'),
									z.literal('INR'),
									z.literal('JOD'),
									z.literal('KHR'),
									z.literal('KRW'),
									z.literal('LKR'),
									z.literal('MAD'),
									z.literal('MXN'),
									z.literal('MYR'),
									z.literal('NOK'),
									z.literal('OMR'),
									z.literal('PAB'),
									z.literal('SAR'),
									z.literal('TWD'),
									z.literal('USD'),
								]),
								cents: z.number(),
								displayValue: z.string(),
							}),
						}),
						reservation: z.object({
							id: z.string(),
							facilityName: z.string(),
							courseName: z.string(),
							facilityLogoUrl: z.union([z.string(), z.null(), z.undefined()]).optional(),
							date: z.object({
								epoch: z.number(),
								tz: z.string(),
							}),
							publicRate: z
								.union([
									z.object({
										value: z.string(),
										code: z.union([
											z.literal('AED'),
											z.literal('AUD'),
											z.literal('AZN'),
											z.literal('BGN'),
											z.literal('BHD'),
											z.literal('BSD'),
											z.literal('CAD'),
											z.literal('CHF'),
											z.literal('CRC'),
											z.literal('CZK'),
											z.literal('EGP'),
											z.literal('EUR'),
											z.literal('GBP'),
											z.literal('HNL'),
											z.literal('INR'),
											z.literal('JOD'),
											z.literal('KHR'),
											z.literal('KRW'),
											z.literal('LKR'),
											z.literal('MAD'),
											z.literal('MXN'),
											z.literal('MYR'),
											z.literal('NOK'),
											z.literal('OMR'),
											z.literal('PAB'),
											z.literal('SAR'),
											z.literal('TWD'),
											z.literal('USD'),
										]),
										cents: z.number(),
										displayValue: z.string(),
									}),
									z.null(),
									z.undefined(),
								])
								.optional(),
							accessRate: z
								.union([
									z.object({
										value: z.string(),
										code: z.union([
											z.literal('AED'),
											z.literal('AUD'),
											z.literal('AZN'),
											z.literal('BGN'),
											z.literal('BHD'),
											z.literal('BSD'),
											z.literal('CAD'),
											z.literal('CHF'),
											z.literal('CRC'),
											z.literal('CZK'),
											z.literal('EGP'),
											z.literal('EUR'),
											z.literal('GBP'),
											z.literal('HNL'),
											z.literal('INR'),
											z.literal('JOD'),
											z.literal('KHR'),
											z.literal('KRW'),
											z.literal('LKR'),
											z.literal('MAD'),
											z.literal('MXN'),
											z.literal('MYR'),
											z.literal('NOK'),
											z.literal('OMR'),
											z.literal('PAB'),
											z.literal('SAR'),
											z.literal('TWD'),
											z.literal('USD'),
										]),
										cents: z.number(),
										displayValue: z.string(),
									}),
									z.null(),
									z.undefined(),
								])
								.optional(),
							accessPlusRate: z
								.union([
									z.object({
										value: z.string(),
										code: z.union([
											z.literal('AED'),
											z.literal('AUD'),
											z.literal('AZN'),
											z.literal('BGN'),
											z.literal('BHD'),
											z.literal('BSD'),
											z.literal('CAD'),
											z.literal('CHF'),
											z.literal('CRC'),
											z.literal('CZK'),
											z.literal('EGP'),
											z.literal('EUR'),
											z.literal('GBP'),
											z.literal('HNL'),
											z.literal('INR'),
											z.literal('JOD'),
											z.literal('KHR'),
											z.literal('KRW'),
											z.literal('LKR'),
											z.literal('MAD'),
											z.literal('MXN'),
											z.literal('MYR'),
											z.literal('NOK'),
											z.literal('OMR'),
											z.literal('PAB'),
											z.literal('SAR'),
											z.literal('TWD'),
											z.literal('USD'),
										]),
										cents: z.number(),
										displayValue: z.string(),
									}),
									z.null(),
									z.undefined(),
								])
								.optional(),
						}),
					}),
				),
			}),
			z.null(),
			z.undefined(),
		])
		.optional(),
	missedAccessSavings: z
		.union([
			z.object({
				totalAccessDiscount: z.object({
					value: z.string(),
					code: z.union([
						z.literal('AED'),
						z.literal('AUD'),
						z.literal('AZN'),
						z.literal('BGN'),
						z.literal('BHD'),
						z.literal('BSD'),
						z.literal('CAD'),
						z.literal('CHF'),
						z.literal('CRC'),
						z.literal('CZK'),
						z.literal('EGP'),
						z.literal('EUR'),
						z.literal('GBP'),
						z.literal('HNL'),
						z.literal('INR'),
						z.literal('JOD'),
						z.literal('KHR'),
						z.literal('KRW'),
						z.literal('LKR'),
						z.literal('MAD'),
						z.literal('MXN'),
						z.literal('MYR'),
						z.literal('NOK'),
						z.literal('OMR'),
						z.literal('PAB'),
						z.literal('SAR'),
						z.literal('TWD'),
						z.literal('USD'),
					]),
					cents: z.number(),
					displayValue: z.string(),
				}),
				totalAccessPlusDiscount: z.object({
					value: z.string(),
					code: z.union([
						z.literal('AED'),
						z.literal('AUD'),
						z.literal('AZN'),
						z.literal('BGN'),
						z.literal('BHD'),
						z.literal('BSD'),
						z.literal('CAD'),
						z.literal('CHF'),
						z.literal('CRC'),
						z.literal('CZK'),
						z.literal('EGP'),
						z.literal('EUR'),
						z.literal('GBP'),
						z.literal('HNL'),
						z.literal('INR'),
						z.literal('JOD'),
						z.literal('KHR'),
						z.literal('KRW'),
						z.literal('LKR'),
						z.literal('MAD'),
						z.literal('MXN'),
						z.literal('MYR'),
						z.literal('NOK'),
						z.literal('OMR'),
						z.literal('PAB'),
						z.literal('SAR'),
						z.literal('TWD'),
						z.literal('USD'),
					]),
					cents: z.number(),
					displayValue: z.string(),
				}),
				reservations: z.array(
					z.object({
						savings: z.object({
							accessDiscount: z.object({
								value: z.string(),
								code: z.union([
									z.literal('AED'),
									z.literal('AUD'),
									z.literal('AZN'),
									z.literal('BGN'),
									z.literal('BHD'),
									z.literal('BSD'),
									z.literal('CAD'),
									z.literal('CHF'),
									z.literal('CRC'),
									z.literal('CZK'),
									z.literal('EGP'),
									z.literal('EUR'),
									z.literal('GBP'),
									z.literal('HNL'),
									z.literal('INR'),
									z.literal('JOD'),
									z.literal('KHR'),
									z.literal('KRW'),
									z.literal('LKR'),
									z.literal('MAD'),
									z.literal('MXN'),
									z.literal('MYR'),
									z.literal('NOK'),
									z.literal('OMR'),
									z.literal('PAB'),
									z.literal('SAR'),
									z.literal('TWD'),
									z.literal('USD'),
								]),
								cents: z.number(),
								displayValue: z.string(),
							}),
							accessPlusDiscount: z.object({
								value: z.string(),
								code: z.union([
									z.literal('AED'),
									z.literal('AUD'),
									z.literal('AZN'),
									z.literal('BGN'),
									z.literal('BHD'),
									z.literal('BSD'),
									z.literal('CAD'),
									z.literal('CHF'),
									z.literal('CRC'),
									z.literal('CZK'),
									z.literal('EGP'),
									z.literal('EUR'),
									z.literal('GBP'),
									z.literal('HNL'),
									z.literal('INR'),
									z.literal('JOD'),
									z.literal('KHR'),
									z.literal('KRW'),
									z.literal('LKR'),
									z.literal('MAD'),
									z.literal('MXN'),
									z.literal('MYR'),
									z.literal('NOK'),
									z.literal('OMR'),
									z.literal('PAB'),
									z.literal('SAR'),
									z.literal('TWD'),
									z.literal('USD'),
								]),
								cents: z.number(),
								displayValue: z.string(),
							}),
						}),
						reservation: z.object({
							id: z.string(),
							facilityName: z.string(),
							courseName: z.string(),
							facilityLogoUrl: z.union([z.string(), z.null(), z.undefined()]).optional(),
							date: z.object({
								epoch: z.number(),
								tz: z.string(),
							}),
							publicRate: z
								.union([
									z.object({
										value: z.string(),
										code: z.union([
											z.literal('AED'),
											z.literal('AUD'),
											z.literal('AZN'),
											z.literal('BGN'),
											z.literal('BHD'),
											z.literal('BSD'),
											z.literal('CAD'),
											z.literal('CHF'),
											z.literal('CRC'),
											z.literal('CZK'),
											z.literal('EGP'),
											z.literal('EUR'),
											z.literal('GBP'),
											z.literal('HNL'),
											z.literal('INR'),
											z.literal('JOD'),
											z.literal('KHR'),
											z.literal('KRW'),
											z.literal('LKR'),
											z.literal('MAD'),
											z.literal('MXN'),
											z.literal('MYR'),
											z.literal('NOK'),
											z.literal('OMR'),
											z.literal('PAB'),
											z.literal('SAR'),
											z.literal('TWD'),
											z.literal('USD'),
										]),
										cents: z.number(),
										displayValue: z.string(),
									}),
									z.null(),
									z.undefined(),
								])
								.optional(),
							accessRate: z
								.union([
									z.object({
										value: z.string(),
										code: z.union([
											z.literal('AED'),
											z.literal('AUD'),
											z.literal('AZN'),
											z.literal('BGN'),
											z.literal('BHD'),
											z.literal('BSD'),
											z.literal('CAD'),
											z.literal('CHF'),
											z.literal('CRC'),
											z.literal('CZK'),
											z.literal('EGP'),
											z.literal('EUR'),
											z.literal('GBP'),
											z.literal('HNL'),
											z.literal('INR'),
											z.literal('JOD'),
											z.literal('KHR'),
											z.literal('KRW'),
											z.literal('LKR'),
											z.literal('MAD'),
											z.literal('MXN'),
											z.literal('MYR'),
											z.literal('NOK'),
											z.literal('OMR'),
											z.literal('PAB'),
											z.literal('SAR'),
											z.literal('TWD'),
											z.literal('USD'),
										]),
										cents: z.number(),
										displayValue: z.string(),
									}),
									z.null(),
									z.undefined(),
								])
								.optional(),
							accessPlusRate: z
								.union([
									z.object({
										value: z.string(),
										code: z.union([
											z.literal('AED'),
											z.literal('AUD'),
											z.literal('AZN'),
											z.literal('BGN'),
											z.literal('BHD'),
											z.literal('BSD'),
											z.literal('CAD'),
											z.literal('CHF'),
											z.literal('CRC'),
											z.literal('CZK'),
											z.literal('EGP'),
											z.literal('EUR'),
											z.literal('GBP'),
											z.literal('HNL'),
											z.literal('INR'),
											z.literal('JOD'),
											z.literal('KHR'),
											z.literal('KRW'),
											z.literal('LKR'),
											z.literal('MAD'),
											z.literal('MXN'),
											z.literal('MYR'),
											z.literal('NOK'),
											z.literal('OMR'),
											z.literal('PAB'),
											z.literal('SAR'),
											z.literal('TWD'),
											z.literal('USD'),
										]),
										cents: z.number(),
										displayValue: z.string(),
									}),
									z.null(),
									z.undefined(),
								])
								.optional(),
						}),
					}),
				),
			}),
			z.null(),
			z.undefined(),
		])
		.optional(),
});

export type OperatorUpdateUserBodyDto = z.infer<typeof OperatorUpdateUserBodyDto>;
export const OperatorUpdateUserBodyDto = z.object({
	email: z.string().optional(),
	phone: z.union([z.string(), z.null()]).optional(),
	firstName: z.string().optional(),
	lastName: z.string().optional(),
	postalCode: z.string().optional(),
	street1: z.union([z.string(), z.null()]).optional(),
	street2: z.union([z.string(), z.null()]).optional(),
	city: z.union([z.string(), z.null()]).optional(),
	state: z.union([z.string(), z.null()]).optional(),
	country: z.union([z.string(), z.null()]).optional(),
});

export type OperatorClaimPassesBodyDto = z.infer<typeof OperatorClaimPassesBodyDto>;
export const OperatorClaimPassesBodyDto = z.object({
	count: z.number(),
});

export type OperatorUpdateUserRewardLevelBodyDto = z.infer<typeof OperatorUpdateUserRewardLevelBodyDto>;
export const OperatorUpdateUserRewardLevelBodyDto = z.object({
	rewardLevel: z.union([z.literal('MEMBER'), z.literal('SILVER'), z.literal('GOLD'), z.literal('PLATINUM')]),
});

export type AddTransactionDto = z.infer<typeof AddTransactionDto>;
export const AddTransactionDto = z.object({
	amount: z.number(),
	facilityId: z.string(),
	notes: z.union([z.string(), z.undefined()]).optional(),
	currencyCode: z.string(),
	timestamp: z
		.union([
			z.object({
				epoch: z.number(),
				tz: z.string(),
			}),
			z.undefined(),
		])
		.optional(),
	reason: z
		.union([z.literal('CLUBS'), z.literal('CLOTHING'), z.literal('TEETIME'), z.literal('OTHER'), z.undefined()])
		.optional(),
});

export type UserRewardTransactionDto = z.infer<typeof UserRewardTransactionDto>;
export const UserRewardTransactionDto = z.object({
	id: z.string(),
	points: z.number(),
	facilityName: z.string(),
	createdAt: z.string(),
	transactionType: z.union([z.literal('AWARD'), z.literal('CREDIT'), z.literal('REDEMPTION')]),
	notes: z.union([z.string(), z.null(), z.undefined()]).optional(),
	amount: z.object({
		value: z.string(),
		code: z.union([
			z.literal('AED'),
			z.literal('AUD'),
			z.literal('AZN'),
			z.literal('BGN'),
			z.literal('BHD'),
			z.literal('BSD'),
			z.literal('CAD'),
			z.literal('CHF'),
			z.literal('CRC'),
			z.literal('CZK'),
			z.literal('EGP'),
			z.literal('EUR'),
			z.literal('GBP'),
			z.literal('HNL'),
			z.literal('INR'),
			z.literal('JOD'),
			z.literal('KHR'),
			z.literal('KRW'),
			z.literal('LKR'),
			z.literal('MAD'),
			z.literal('MXN'),
			z.literal('MYR'),
			z.literal('NOK'),
			z.literal('OMR'),
			z.literal('PAB'),
			z.literal('SAR'),
			z.literal('TWD'),
			z.literal('USD'),
		]),
		cents: z.number(),
		displayValue: z.string(),
	}),
});

export type UpdateTransactionDto = z.infer<typeof UpdateTransactionDto>;
export const UpdateTransactionDto = z.object({
	amount: z.number(),
	facilityId: z.string(),
	notes: z.union([z.string(), z.undefined()]).optional(),
	currencyCode: z.string(),
	timestamp: z
		.union([
			z.object({
				epoch: z.number(),
				tz: z.string(),
			}),
			z.undefined(),
		])
		.optional(),
	reason: z
		.union([z.literal('CLUBS'), z.literal('CLOTHING'), z.literal('TEETIME'), z.literal('OTHER'), z.undefined()])
		.optional(),
});

export type OperatorRedeemRewardDto = z.infer<typeof OperatorRedeemRewardDto>;
export const OperatorRedeemRewardDto = z.object({
	rewardId: z.string(),
});

export type UserRewardTransactionListDto = z.infer<typeof UserRewardTransactionListDto>;
export const UserRewardTransactionListDto = z.object({
	transactions: z.array(
		z.object({
			id: z.string(),
			points: z.number(),
			facilityName: z.string(),
			createdAt: z.string(),
			transactionType: z.union([z.literal('AWARD'), z.literal('CREDIT'), z.literal('REDEMPTION')]),
			notes: z.union([z.string(), z.null(), z.undefined()]).optional(),
			amount: z.object({
				value: z.string(),
				code: z.union([
					z.literal('AED'),
					z.literal('AUD'),
					z.literal('AZN'),
					z.literal('BGN'),
					z.literal('BHD'),
					z.literal('BSD'),
					z.literal('CAD'),
					z.literal('CHF'),
					z.literal('CRC'),
					z.literal('CZK'),
					z.literal('EGP'),
					z.literal('EUR'),
					z.literal('GBP'),
					z.literal('HNL'),
					z.literal('INR'),
					z.literal('JOD'),
					z.literal('KHR'),
					z.literal('KRW'),
					z.literal('LKR'),
					z.literal('MAD'),
					z.literal('MXN'),
					z.literal('MYR'),
					z.literal('NOK'),
					z.literal('OMR'),
					z.literal('PAB'),
					z.literal('SAR'),
					z.literal('TWD'),
					z.literal('USD'),
				]),
				cents: z.number(),
				displayValue: z.string(),
			}),
		}),
	),
	cursor: z.union([z.string(), z.undefined()]).optional(),
});

export type OperatorFacilityAndRoleDto = z.infer<typeof OperatorFacilityAndRoleDto>;
export const OperatorFacilityAndRoleDto = z.object({
	facility: z.object({
		id: z.string(),
		name: z.string(),
		slug: z.string(),
		logo: z.union([z.string(), z.null(), z.undefined()]).optional(),
		timezone: z.string(),
		currencyCode: z.union([
			z.literal('AED'),
			z.literal('AUD'),
			z.literal('AZN'),
			z.literal('BGN'),
			z.literal('BHD'),
			z.literal('BSD'),
			z.literal('CAD'),
			z.literal('CHF'),
			z.literal('CRC'),
			z.literal('CZK'),
			z.literal('EGP'),
			z.literal('EUR'),
			z.literal('GBP'),
			z.literal('HNL'),
			z.literal('INR'),
			z.literal('JOD'),
			z.literal('KHR'),
			z.literal('KRW'),
			z.literal('LKR'),
			z.literal('MAD'),
			z.literal('MXN'),
			z.literal('MYR'),
			z.literal('NOK'),
			z.literal('OMR'),
			z.literal('PAB'),
			z.literal('SAR'),
			z.literal('TWD'),
			z.literal('USD'),
		]),
		isAvailable: z.boolean(),
		isBookable: z.boolean(),
		supportsTroonAccess: z.boolean(),
		supportsTroonAccessAssociateCommisions: z.boolean(),
		supportsTroonRewards: z.boolean(),
		supportsTroonAdvantage: z.boolean(),
	}),
	role: z.union([z.literal('ADMIN'), z.literal('MEMBER')]),
});

export type OperatorSignupUserDto = z.infer<typeof OperatorSignupUserDto>;
export const OperatorSignupUserDto = z.object({
	email: z.string(),
	firstName: z.string(),
	lastName: z.string(),
	zipcode: z.string(),
	city: z.union([z.string(), z.null(), z.undefined()]).optional(),
	state: z.union([z.string(), z.null(), z.undefined()]).optional(),
	country: z.union([z.string(), z.null(), z.undefined()]).optional(),
});

export type OperatorRewardsUserDto = z.infer<typeof OperatorRewardsUserDto>;
export const OperatorRewardsUserDto = z.object({
	rewardsId: z.string(),
	email: z.string(),
	firstName: z.union([z.string(), z.null(), z.undefined()]).optional(),
	lastName: z.union([z.string(), z.null(), z.undefined()]).optional(),
	troonAccessProductType: z
		.union([z.literal('TROON_ACCESS'), z.literal('TROON_ACCESS_PLUS'), z.null(), z.undefined()])
		.optional(),
	rewardsLevel: z.union([z.literal('MEMBER'), z.literal('SILVER'), z.literal('GOLD'), z.literal('PLATINUM')]),
	city: z.union([z.string(), z.null(), z.undefined()]).optional(),
	state: z.union([z.string(), z.null(), z.undefined()]).optional(),
	country: z.union([z.string(), z.null(), z.undefined()]).optional(),
	isDisabled: z.boolean(),
});

export type OperatorMembershipListWithInvitesDto = z.infer<typeof OperatorMembershipListWithInvitesDto>;
export const OperatorMembershipListWithInvitesDto = z.object({
	members: z.array(
		z.object({
			id: z.string(),
			role: z.union([z.literal('ADMIN'), z.literal('MEMBER')]),
			operator: z.object({
				id: z.string(),
				email: z.string(),
				firstName: z.union([z.string(), z.undefined()]).optional(),
				lastName: z.union([z.string(), z.undefined()]).optional(),
			}),
		}),
	),
	invites: z.array(
		z.object({
			id: z.string(),
			email: z.string(),
			createdAt: z.string(),
			expiresAt: z.string(),
			role: z.union([z.literal('ADMIN'), z.literal('MEMBER')]),
			state: z.union([z.literal('PENDING'), z.literal('ACCEPTED'), z.literal('REJECTED'), z.literal('CANCELLED')]),
			inviter: z.object({
				id: z.string(),
				firstName: z.string(),
				lastName: z.string(),
			}),
		}),
	),
});

export type OperatorInviteMemberBodyDto = z.infer<typeof OperatorInviteMemberBodyDto>;
export const OperatorInviteMemberBodyDto = z.object({
	email: z.string(),
	role: z.union([z.literal('ADMIN'), z.literal('MEMBER')]),
});

export type OperatorInviteDto = z.infer<typeof OperatorInviteDto>;
export const OperatorInviteDto = z.object({
	email: z.string(),
	createdAt: z.string(),
	expiresAt: z.string(),
});

export type OperatorUpdateMemberBodyDto = z.infer<typeof OperatorUpdateMemberBodyDto>;
export const OperatorUpdateMemberBodyDto = z.object({
	role: z.union([z.literal('ADMIN'), z.literal('MEMBER')]).optional(),
});

export type OperatorFacilityMembershipDto = z.infer<typeof OperatorFacilityMembershipDto>;
export const OperatorFacilityMembershipDto = z.object({
	id: z.string(),
	role: z.union([z.literal('ADMIN'), z.literal('MEMBER')]),
	operator: z.object({
		id: z.string(),
		email: z.string(),
		firstName: z.union([z.string(), z.undefined()]).optional(),
		lastName: z.union([z.string(), z.undefined()]).optional(),
	}),
});

export type OperatorFacilityRedemptionRewardsListDto = z.infer<typeof OperatorFacilityRedemptionRewardsListDto>;
export const OperatorFacilityRedemptionRewardsListDto = z.object({
	rewards: z.array(
		z.object({
			id: z.string(),
			course: z.string(),
			points: z.number(),
			cash: z.union([
				z.object({
					value: z.string(),
					code: z.union([
						z.literal('AED'),
						z.literal('AUD'),
						z.literal('AZN'),
						z.literal('BGN'),
						z.literal('BHD'),
						z.literal('BSD'),
						z.literal('CAD'),
						z.literal('CHF'),
						z.literal('CRC'),
						z.literal('CZK'),
						z.literal('EGP'),
						z.literal('EUR'),
						z.literal('GBP'),
						z.literal('HNL'),
						z.literal('INR'),
						z.literal('JOD'),
						z.literal('KHR'),
						z.literal('KRW'),
						z.literal('LKR'),
						z.literal('MAD'),
						z.literal('MXN'),
						z.literal('MYR'),
						z.literal('NOK'),
						z.literal('OMR'),
						z.literal('PAB'),
						z.literal('SAR'),
						z.literal('TWD'),
						z.literal('USD'),
					]),
					cents: z.number(),
					displayValue: z.string(),
				}),
				z.null(),
			]),
		}),
	),
});

export type OperatorReservationListDto = z.infer<typeof OperatorReservationListDto>;
export const OperatorReservationListDto = z.object({
	reservations: z.array(
		z.object({
			id: z.string(),
			course: z.object({
				id: z.string(),
				name: z.string(),
			}),
			users: z.array(
				z.object({
					user: z
						.union([
							z.object({
								rewardsId: z.string(),
								email: z.string(),
								firstName: z.union([z.string(), z.null(), z.undefined()]).optional(),
								lastName: z.union([z.string(), z.null(), z.undefined()]).optional(),
								troonAccessProductType: z
									.union([z.literal('TROON_ACCESS'), z.literal('TROON_ACCESS_PLUS'), z.null(), z.undefined()])
									.optional(),
								rewardsLevel: z.union([
									z.literal('MEMBER'),
									z.literal('SILVER'),
									z.literal('GOLD'),
									z.literal('PLATINUM'),
								]),
								city: z.union([z.string(), z.null(), z.undefined()]).optional(),
								state: z.union([z.string(), z.null(), z.undefined()]).optional(),
								country: z.union([z.string(), z.null(), z.undefined()]).optional(),
								isDisabled: z.boolean(),
							}),
							z.null(),
							z.undefined(),
						])
						.optional(),
					guestPass: z.boolean(),
					rateType: z.union([
						z.literal('PUBLIC'),
						z.literal('PUBLIC_PREPAID'),
						z.literal('PUBLIC_EXTRA'),
						z.literal('PUBLIC_EXTRA_PREPAID'),
						z.literal('TROON_ACCESS'),
						z.literal('TROON_ACCESS_PREPAID'),
						z.literal('TROON_ACCESS_PLUS'),
						z.literal('TROON_ACCESS_PLUS_PREPAID'),
						z.literal('TROON_CARD_TWOSOME'),
						z.literal('TROON_CARD_FOURSOME'),
						z.literal('LOCAL_CARD'),
					]),
					rate: z.object({
						value: z.string(),
						code: z.union([
							z.literal('AED'),
							z.literal('AUD'),
							z.literal('AZN'),
							z.literal('BGN'),
							z.literal('BHD'),
							z.literal('BSD'),
							z.literal('CAD'),
							z.literal('CHF'),
							z.literal('CRC'),
							z.literal('CZK'),
							z.literal('EGP'),
							z.literal('EUR'),
							z.literal('GBP'),
							z.literal('HNL'),
							z.literal('INR'),
							z.literal('JOD'),
							z.literal('KHR'),
							z.literal('KRW'),
							z.literal('LKR'),
							z.literal('MAD'),
							z.literal('MXN'),
							z.literal('MYR'),
							z.literal('NOK'),
							z.literal('OMR'),
							z.literal('PAB'),
							z.literal('SAR'),
							z.literal('TWD'),
							z.literal('USD'),
						]),
						cents: z.number(),
						displayValue: z.string(),
					}),
				}),
			),
			teeTime: z.object({
				epoch: z.number(),
				tz: z.string(),
			}),
			publicRate: z
				.union([
					z.object({
						value: z.string(),
						code: z.union([
							z.literal('AED'),
							z.literal('AUD'),
							z.literal('AZN'),
							z.literal('BGN'),
							z.literal('BHD'),
							z.literal('BSD'),
							z.literal('CAD'),
							z.literal('CHF'),
							z.literal('CRC'),
							z.literal('CZK'),
							z.literal('EGP'),
							z.literal('EUR'),
							z.literal('GBP'),
							z.literal('HNL'),
							z.literal('INR'),
							z.literal('JOD'),
							z.literal('KHR'),
							z.literal('KRW'),
							z.literal('LKR'),
							z.literal('MAD'),
							z.literal('MXN'),
							z.literal('MYR'),
							z.literal('NOK'),
							z.literal('OMR'),
							z.literal('PAB'),
							z.literal('SAR'),
							z.literal('TWD'),
							z.literal('USD'),
						]),
						cents: z.number(),
						displayValue: z.string(),
					}),
					z.null(),
					z.undefined(),
				])
				.optional(),
			troonAccessRate: z
				.union([
					z.object({
						value: z.string(),
						code: z.union([
							z.literal('AED'),
							z.literal('AUD'),
							z.literal('AZN'),
							z.literal('BGN'),
							z.literal('BHD'),
							z.literal('BSD'),
							z.literal('CAD'),
							z.literal('CHF'),
							z.literal('CRC'),
							z.literal('CZK'),
							z.literal('EGP'),
							z.literal('EUR'),
							z.literal('GBP'),
							z.literal('HNL'),
							z.literal('INR'),
							z.literal('JOD'),
							z.literal('KHR'),
							z.literal('KRW'),
							z.literal('LKR'),
							z.literal('MAD'),
							z.literal('MXN'),
							z.literal('MYR'),
							z.literal('NOK'),
							z.literal('OMR'),
							z.literal('PAB'),
							z.literal('SAR'),
							z.literal('TWD'),
							z.literal('USD'),
						]),
						cents: z.number(),
						displayValue: z.string(),
					}),
					z.null(),
					z.undefined(),
				])
				.optional(),
			troonAccessPlusRate: z
				.union([
					z.object({
						value: z.string(),
						code: z.union([
							z.literal('AED'),
							z.literal('AUD'),
							z.literal('AZN'),
							z.literal('BGN'),
							z.literal('BHD'),
							z.literal('BSD'),
							z.literal('CAD'),
							z.literal('CHF'),
							z.literal('CRC'),
							z.literal('CZK'),
							z.literal('EGP'),
							z.literal('EUR'),
							z.literal('GBP'),
							z.literal('HNL'),
							z.literal('INR'),
							z.literal('JOD'),
							z.literal('KHR'),
							z.literal('KRW'),
							z.literal('LKR'),
							z.literal('MAD'),
							z.literal('MXN'),
							z.literal('MYR'),
							z.literal('NOK'),
							z.literal('OMR'),
							z.literal('PAB'),
							z.literal('SAR'),
							z.literal('TWD'),
							z.literal('USD'),
						]),
						cents: z.number(),
						displayValue: z.string(),
					}),
					z.null(),
					z.undefined(),
				])
				.optional(),
			troonAccessSavings: z
				.union([
					z.object({
						value: z.string(),
						code: z.union([
							z.literal('AED'),
							z.literal('AUD'),
							z.literal('AZN'),
							z.literal('BGN'),
							z.literal('BHD'),
							z.literal('BSD'),
							z.literal('CAD'),
							z.literal('CHF'),
							z.literal('CRC'),
							z.literal('CZK'),
							z.literal('EGP'),
							z.literal('EUR'),
							z.literal('GBP'),
							z.literal('HNL'),
							z.literal('INR'),
							z.literal('JOD'),
							z.literal('KHR'),
							z.literal('KRW'),
							z.literal('LKR'),
							z.literal('MAD'),
							z.literal('MXN'),
							z.literal('MYR'),
							z.literal('NOK'),
							z.literal('OMR'),
							z.literal('PAB'),
							z.literal('SAR'),
							z.literal('TWD'),
							z.literal('USD'),
						]),
						cents: z.number(),
						displayValue: z.string(),
					}),
					z.null(),
					z.undefined(),
				])
				.optional(),
		}),
	),
});

export type SignupStatsResponseDto = z.infer<typeof SignupStatsResponseDto>;
export const SignupStatsResponseDto = z.object({
	data: z.object({
		summary: z.object({
			day: z.object({
				current: z.object({
					date: z.string(),
					count: z.number(),
				}),
				previous: z.object({
					date: z.string(),
					count: z.number(),
				}),
			}),
			week: z.object({
				current: z.object({
					date: z.string(),
					count: z.number(),
				}),
				previous: z.object({
					date: z.string(),
					count: z.number(),
				}),
			}),
			month: z.object({
				current: z.object({
					date: z.string(),
					count: z.number(),
				}),
				previous: z.object({
					date: z.string(),
					count: z.number(),
				}),
			}),
			year: z.object({
				current: z.object({
					date: z.string(),
					count: z.number(),
				}),
				previous: z.object({
					date: z.string(),
					count: z.number(),
				}),
			}),
		}),
		series: z.object({
			byMonth: z.object({
				current: z.array(
					z.object({
						date: z.string(),
						count: z.number(),
					}),
				),
				previous: z.array(
					z.object({
						date: z.string(),
						count: z.number(),
					}),
				),
			}),
		}),
	}),
});

export type RewardsStatsResponseDto = z.infer<typeof RewardsStatsResponseDto>;
export const RewardsStatsResponseDto = z.object({
	data: z.object({
		summary: z.object({
			day: z.object({
				current: z.object({
					date: z.string(),
					awardPoints: z.number(),
					redemptionPoints: z.number(),
				}),
				previous: z.object({
					date: z.string(),
					awardPoints: z.number(),
					redemptionPoints: z.number(),
				}),
			}),
			week: z.object({
				current: z.object({
					date: z.string(),
					awardPoints: z.number(),
					redemptionPoints: z.number(),
				}),
				previous: z.object({
					date: z.string(),
					awardPoints: z.number(),
					redemptionPoints: z.number(),
				}),
			}),
			month: z.object({
				current: z.object({
					date: z.string(),
					awardPoints: z.number(),
					redemptionPoints: z.number(),
				}),
				previous: z.object({
					date: z.string(),
					awardPoints: z.number(),
					redemptionPoints: z.number(),
				}),
			}),
			year: z.object({
				current: z.object({
					date: z.string(),
					awardPoints: z.number(),
					redemptionPoints: z.number(),
				}),
				previous: z.object({
					date: z.string(),
					awardPoints: z.number(),
					redemptionPoints: z.number(),
				}),
			}),
		}),
		series: z.object({
			byMonth: z.object({
				current: z.array(
					z.object({
						date: z.string(),
						awardPoints: z.number(),
						redemptionPoints: z.number(),
					}),
				),
				previous: z.array(
					z.object({
						date: z.string(),
						awardPoints: z.number(),
						redemptionPoints: z.number(),
					}),
				),
			}),
		}),
	}),
});

export type StartUploadMediaBodyDto = z.infer<typeof StartUploadMediaBodyDto>;
export const StartUploadMediaBodyDto = z.object({
	type: z.union([
		z.literal('HERO'),
		z.literal('LOGO'),
		z.literal('GALLERY'),
		z.literal('VIDEO'),
		z.literal('VIDEO_THUMB'),
	]),
	ext: z.string(),
});

export type StartUploadMediaResponseDto = z.infer<typeof StartUploadMediaResponseDto>;
export const StartUploadMediaResponseDto = z.object({
	mediaId: z.string(),
	uploadUrl: z.string(),
});

export type MediaDto = z.infer<typeof MediaDto>;
export const MediaDto = z.object({
	id: z.string(),
	url: z.union([z.string(), z.null(), z.undefined()]).optional(),
	type: z.union([
		z.literal('HERO'),
		z.literal('LOGO'),
		z.literal('GALLERY'),
		z.literal('VIDEO'),
		z.literal('VIDEO_THUMB'),
	]),
});

export type FacilityMediaDto = z.infer<typeof FacilityMediaDto>;
export const FacilityMediaDto = z.object({
	logo: z
		.union([
			z.object({
				id: z.string(),
				url: z.union([z.string(), z.null(), z.undefined()]).optional(),
				type: z.union([
					z.literal('HERO'),
					z.literal('LOGO'),
					z.literal('GALLERY'),
					z.literal('VIDEO'),
					z.literal('VIDEO_THUMB'),
				]),
			}),
			z.null(),
			z.undefined(),
		])
		.optional(),
	hero: z
		.union([
			z.object({
				id: z.string(),
				url: z.union([z.string(), z.null(), z.undefined()]).optional(),
				type: z.union([
					z.literal('HERO'),
					z.literal('LOGO'),
					z.literal('GALLERY'),
					z.literal('VIDEO'),
					z.literal('VIDEO_THUMB'),
				]),
			}),
			z.null(),
			z.undefined(),
		])
		.optional(),
	gallery: z.array(
		z.object({
			id: z.string(),
			url: z.union([z.string(), z.null(), z.undefined()]).optional(),
			type: z.union([
				z.literal('HERO'),
				z.literal('LOGO'),
				z.literal('GALLERY'),
				z.literal('VIDEO'),
				z.literal('VIDEO_THUMB'),
			]),
		}),
	),
});

export type OperatorMembershipFacilityListDto = z.infer<typeof OperatorMembershipFacilityListDto>;
export const OperatorMembershipFacilityListDto = z.object({
	facilities: z.array(
		z.object({
			facility: z.object({
				id: z.string(),
				name: z.string(),
				slug: z.string(),
				logo: z.union([z.string(), z.null(), z.undefined()]).optional(),
				timezone: z.string(),
				currencyCode: z.union([
					z.literal('AED'),
					z.literal('AUD'),
					z.literal('AZN'),
					z.literal('BGN'),
					z.literal('BHD'),
					z.literal('BSD'),
					z.literal('CAD'),
					z.literal('CHF'),
					z.literal('CRC'),
					z.literal('CZK'),
					z.literal('EGP'),
					z.literal('EUR'),
					z.literal('GBP'),
					z.literal('HNL'),
					z.literal('INR'),
					z.literal('JOD'),
					z.literal('KHR'),
					z.literal('KRW'),
					z.literal('LKR'),
					z.literal('MAD'),
					z.literal('MXN'),
					z.literal('MYR'),
					z.literal('NOK'),
					z.literal('OMR'),
					z.literal('PAB'),
					z.literal('SAR'),
					z.literal('TWD'),
					z.literal('USD'),
				]),
				isAvailable: z.boolean(),
				isBookable: z.boolean(),
				supportsTroonAccess: z.boolean(),
				supportsTroonAccessAssociateCommisions: z.boolean(),
				supportsTroonRewards: z.boolean(),
				supportsTroonAdvantage: z.boolean(),
			}),
			role: z.union([z.literal('ADMIN'), z.literal('MEMBER')]),
		}),
	),
});

export type StripePaymentMethodsDto = z.infer<typeof StripePaymentMethodsDto>;
export const StripePaymentMethodsDto = z.object({
	payment_methods: z.array(
		z.object({
			id: z.string(),
			type: z.literal('card'),
			default: z.boolean(),
			card: z.object({
				brand: z.union([
					z.literal('VISA'),
					z.literal('MASTERCARD'),
					z.literal('AMEX'),
					z.literal('DISCOVER'),
					z.literal('OTHER'),
				]),
				last4: z.string(),
				exp_month: z.number(),
				exp_year: z.number(),
			}),
		}),
	),
	client_secret: z.string(),
});

export type AddPaymentMethodBodyDto = z.infer<typeof AddPaymentMethodBodyDto>;
export const AddPaymentMethodBodyDto = z.object({
	payment_method_id: z.string(),
});

export type AddPaymentMethodResponseDto = z.infer<typeof AddPaymentMethodResponseDto>;
export const AddPaymentMethodResponseDto = z.object({
	success: z.boolean(),
	payment_method_id: z.string(),
});

export type ProgramSignupInfoResponseDto = z.infer<typeof ProgramSignupInfoResponseDto>;
export const ProgramSignupInfoResponseDto = z.object({
	data: z.union([
		z.object({
			name: z.string(),
			rewardsLevel: z.union([
				z.literal('MEMBER'),
				z.literal('SILVER'),
				z.literal('GOLD'),
				z.literal('PLATINUM'),
				z.null(),
			]),
		}),
		z.null(),
	]),
});

export type ProgramSignupEnrollResponseDto = z.infer<typeof ProgramSignupEnrollResponseDto>;
export const ProgramSignupEnrollResponseDto = z.object({
	newRewardsLevel: z.union([
		z.literal('MEMBER'),
		z.literal('SILVER'),
		z.literal('GOLD'),
		z.literal('PLATINUM'),
		z.null(),
	]),
	originalRewardsLevel: z.union([z.literal('MEMBER'), z.literal('SILVER'), z.literal('GOLD'), z.literal('PLATINUM')]),
});

export type VisaStatusResponseDto = z.infer<typeof VisaStatusResponseDto>;
export const VisaStatusResponseDto = z.object({
	data: z.union([
		z.object({
			programType: z.union([
				z.literal('VISA_INF'),
				z.literal('VISA_SIG'),
				z.literal('VISA_CAD_INF'),
				z.literal('VISA_CAD_INF_PRIV'),
			]),
			privateEligible: z.boolean(),
			expiresAt: z.string(),
			canUpgrade: z.boolean(),
		}),
		z.null(),
	]),
});

export type VisaTokenResultDto = z.infer<typeof VisaTokenResultDto>;
export const VisaTokenResultDto = z.object({
	state: z.union([z.literal('PENDING'), z.literal('VERIFIED'), z.literal('FAILED')]),
	visaStatus: z
		.union([
			z.object({
				programType: z.union([
					z.literal('VISA_INF'),
					z.literal('VISA_SIG'),
					z.literal('VISA_CAD_INF'),
					z.literal('VISA_CAD_INF_PRIV'),
				]),
				privateEligible: z.boolean(),
				expiresAt: z.string(),
				canUpgrade: z.boolean(),
			}),
			z.null(),
			z.undefined(),
		])
		.optional(),
	originalRewardsLevel: z.union([z.literal('MEMBER'), z.literal('SILVER'), z.literal('GOLD'), z.literal('PLATINUM')]),
	newRewardsLevel: z
		.union([
			z.literal('MEMBER'),
			z.literal('SILVER'),
			z.literal('GOLD'),
			z.literal('PLATINUM'),
			z.null(),
			z.undefined(),
		])
		.optional(),
});

export type VisaTokenDto = z.infer<typeof VisaTokenDto>;
export const VisaTokenDto = z.object({
	token: z.string(),
});

export type PriveTeeTimeRequestDto = z.infer<typeof PriveTeeTimeRequestDto>;
export const PriveTeeTimeRequestDto = z.object({
	id: z.string(),
	guestCount: z.number(),
	memberCount: z.number(),
	dayTime: z.object({
		epoch: z.number(),
		tz: z.string(),
	}),
	type: z.union([z.literal('PRIVE'), z.literal('VISA'), z.literal('TROON_ACCESS')]),
	facility: z.object({
		id: z.string(),
		name: z.string(),
	}),
});

export type PriveRequestBodyDto = z.infer<typeof PriveRequestBodyDto>;
export const PriveRequestBodyDto = z.object({
	facilityId: z.string(),
	dayTime: z.object({
		epoch: z.number(),
		tz: z.string(),
	}),
	guests: z.number(),
	comments: z.union([z.string(), z.undefined()]).optional(),
	priveRequestType: z
		.union([z.literal('PRIVE'), z.literal('VISA'), z.literal('TROON_ACCESS'), z.undefined()])
		.optional(),
});

export type UnlimitedGuestPassPreviewBodyDto = z.infer<typeof UnlimitedGuestPassPreviewBodyDto>;
export const UnlimitedGuestPassPreviewBodyDto = z.object({
	subscription_id: z.string(),
	promo_code: z.union([z.string(), z.undefined()]).optional(),
});

export type UnlimitedGuestPassPreviewResponseDto = z.infer<typeof UnlimitedGuestPassPreviewResponseDto>;
export const UnlimitedGuestPassPreviewResponseDto = z.object({
	items: z.array(
		z.object({
			name: z.union([z.string(), z.null()]),
			price: z.number(),
			priceDisplay: z.string(),
			description: z.union([z.string(), z.undefined()]).optional(),
			discount: z.boolean(),
		}),
	),
	amountDueToday: z.string(),
	renewalDate: z.string(),
	renewalPrice: z.string(),
});

export type UnlimitedGuestPassUpgradeBodyDto = z.infer<typeof UnlimitedGuestPassUpgradeBodyDto>;
export const UnlimitedGuestPassUpgradeBodyDto = z.object({
	subscription_id: z.string(),
	payment_method_id: z.union([z.string(), z.undefined()]).optional(),
	promo_code: z.union([z.string(), z.undefined()]).optional(),
});

export type UnlimitedGuestPassUpgradeResponseDto = z.infer<typeof UnlimitedGuestPassUpgradeResponseDto>;
export const UnlimitedGuestPassUpgradeResponseDto = z.object({
	id: z.string(),
	status: z.union([
		z.literal('PENDING'),
		z.literal('ACTIVE'),
		z.literal('CANCELLED'),
		z.literal('EXPIRED'),
		z.literal('REFUNDED'),
	]),
	subscriptionName: z.string(),
	startDayTime: z
		.union([
			z.object({
				date: z.string(),
				timezone: z.string(),
			}),
			z.null(),
			z.undefined(),
		])
		.optional(),
	endDayTime: z
		.union([
			z.object({
				date: z.string(),
				timezone: z.string(),
			}),
			z.null(),
			z.undefined(),
		])
		.optional(),
	totalAmount: z.object({
		value: z.string(),
		code: z.union([
			z.literal('AED'),
			z.literal('AUD'),
			z.literal('AZN'),
			z.literal('BGN'),
			z.literal('BHD'),
			z.literal('BSD'),
			z.literal('CAD'),
			z.literal('CHF'),
			z.literal('CRC'),
			z.literal('CZK'),
			z.literal('EGP'),
			z.literal('EUR'),
			z.literal('GBP'),
			z.literal('HNL'),
			z.literal('INR'),
			z.literal('JOD'),
			z.literal('KHR'),
			z.literal('KRW'),
			z.literal('LKR'),
			z.literal('MAD'),
			z.literal('MXN'),
			z.literal('MYR'),
			z.literal('NOK'),
			z.literal('OMR'),
			z.literal('PAB'),
			z.literal('SAR'),
			z.literal('TWD'),
			z.literal('USD'),
		]),
		cents: z.number(),
		displayValue: z.string(),
	}),
	subtotalAmount: z.object({
		value: z.string(),
		code: z.union([
			z.literal('AED'),
			z.literal('AUD'),
			z.literal('AZN'),
			z.literal('BGN'),
			z.literal('BHD'),
			z.literal('BSD'),
			z.literal('CAD'),
			z.literal('CHF'),
			z.literal('CRC'),
			z.literal('CZK'),
			z.literal('EGP'),
			z.literal('EUR'),
			z.literal('GBP'),
			z.literal('HNL'),
			z.literal('INR'),
			z.literal('JOD'),
			z.literal('KHR'),
			z.literal('KRW'),
			z.literal('LKR'),
			z.literal('MAD'),
			z.literal('MXN'),
			z.literal('MYR'),
			z.literal('NOK'),
			z.literal('OMR'),
			z.literal('PAB'),
			z.literal('SAR'),
			z.literal('TWD'),
			z.literal('USD'),
		]),
		cents: z.number(),
		displayValue: z.string(),
	}),
	discountAmount: z.object({
		value: z.string(),
		code: z.union([
			z.literal('AED'),
			z.literal('AUD'),
			z.literal('AZN'),
			z.literal('BGN'),
			z.literal('BHD'),
			z.literal('BSD'),
			z.literal('CAD'),
			z.literal('CHF'),
			z.literal('CRC'),
			z.literal('CZK'),
			z.literal('EGP'),
			z.literal('EUR'),
			z.literal('GBP'),
			z.literal('HNL'),
			z.literal('INR'),
			z.literal('JOD'),
			z.literal('KHR'),
			z.literal('KRW'),
			z.literal('LKR'),
			z.literal('MAD'),
			z.literal('MXN'),
			z.literal('MYR'),
			z.literal('NOK'),
			z.literal('OMR'),
			z.literal('PAB'),
			z.literal('SAR'),
			z.literal('TWD'),
			z.literal('USD'),
		]),
		cents: z.number(),
		displayValue: z.string(),
	}),
	taxAmount: z.object({
		value: z.string(),
		code: z.union([
			z.literal('AED'),
			z.literal('AUD'),
			z.literal('AZN'),
			z.literal('BGN'),
			z.literal('BHD'),
			z.literal('BSD'),
			z.literal('CAD'),
			z.literal('CHF'),
			z.literal('CRC'),
			z.literal('CZK'),
			z.literal('EGP'),
			z.literal('EUR'),
			z.literal('GBP'),
			z.literal('HNL'),
			z.literal('INR'),
			z.literal('JOD'),
			z.literal('KHR'),
			z.literal('KRW'),
			z.literal('LKR'),
			z.literal('MAD'),
			z.literal('MXN'),
			z.literal('MYR'),
			z.literal('NOK'),
			z.literal('OMR'),
			z.literal('PAB'),
			z.literal('SAR'),
			z.literal('TWD'),
			z.literal('USD'),
		]),
		cents: z.number(),
		displayValue: z.string(),
	}),
	nextInvoiceAmount: z
		.union([
			z.object({
				value: z.string(),
				code: z.union([
					z.literal('AED'),
					z.literal('AUD'),
					z.literal('AZN'),
					z.literal('BGN'),
					z.literal('BHD'),
					z.literal('BSD'),
					z.literal('CAD'),
					z.literal('CHF'),
					z.literal('CRC'),
					z.literal('CZK'),
					z.literal('EGP'),
					z.literal('EUR'),
					z.literal('GBP'),
					z.literal('HNL'),
					z.literal('INR'),
					z.literal('JOD'),
					z.literal('KHR'),
					z.literal('KRW'),
					z.literal('LKR'),
					z.literal('MAD'),
					z.literal('MXN'),
					z.literal('MYR'),
					z.literal('NOK'),
					z.literal('OMR'),
					z.literal('PAB'),
					z.literal('SAR'),
					z.literal('TWD'),
					z.literal('USD'),
				]),
				cents: z.number(),
				displayValue: z.string(),
			}),
			z.undefined(),
		])
		.optional(),
	nextInvoiceDate: z
		.union([
			z.object({
				date: z.string(),
				timezone: z.string(),
			}),
			z.null(),
			z.undefined(),
		])
		.optional(),
	autoRenews: z.boolean(),
	productType: z.union([z.literal('TROON_ACCESS'), z.literal('TROON_ACCESS_PLUS')]),
	manageSubscriptionUrl: z.union([z.string(), z.undefined()]).optional(),
	updateSubscriptionUrl: z.union([z.string(), z.undefined()]).optional(),
	unlimitedGuestPass: z.boolean(),
	product: z.object({
		id: z.string(),
		subscriptionName: z.string(),
		type: z.union([z.literal('TROON_ACCESS'), z.literal('TROON_ACCESS_PLUS')]),
	}),
	stripeSubscription: z.object({
		id: z.string(),
		subscriptionId: z.string(),
		clientSecret: z.string(),
		clientSecretIntentType: z.string(),
		customerId: z.string(),
		totalAmount: z.string(),
		subtotalAmount: z.string(),
		discountAmount: z.string(),
		taxAmount: z.string(),
		nextInvoiceAmount: z.union([z.string(), z.null(), z.undefined()]).optional(),
		startDate: z.union([z.unknown(), z.null(), z.undefined()]).optional(),
		endDate: z.union([z.unknown(), z.null(), z.undefined()]).optional(),
		nextInvoiceDate: z.union([z.unknown(), z.null(), z.undefined()]).optional(),
		autoRenews: z.boolean(),
		promoCode: z.union([z.string(), z.null(), z.undefined()]).optional(),
	}),
});

export type post_OperatorAuthController_signup_v0 = typeof post_OperatorAuthController_signup_v0;
export const post_OperatorAuthController_signup_v0 = {
	method: z.literal('POST'),
	path: z.literal('/v0/operator/auth/signup'),
	requestFormat: z.literal('json'),
	parameters: z.object({
		body: OperatorSignupBodyDto,
	}),
	response: AuthOperatorDto,
};

export type post_OperatorAuthController_login_v0 = typeof post_OperatorAuthController_login_v0;
export const post_OperatorAuthController_login_v0 = {
	method: z.literal('POST'),
	path: z.literal('/v0/operator/auth/login'),
	requestFormat: z.literal('json'),
	parameters: z.object({
		body: OperatorLoginBodyDto,
	}),
	response: OperatorLoginResponseDto,
};

export type post_OperatorAuthController_logout_v0 = typeof post_OperatorAuthController_logout_v0;
export const post_OperatorAuthController_logout_v0 = {
	method: z.literal('POST'),
	path: z.literal('/v0/operator/auth/logout'),
	requestFormat: z.literal('json'),
	parameters: z.never(),
	response: SuccessResponseDto,
};

export type post_OperatorAuthController_forgotPassword_v0 = typeof post_OperatorAuthController_forgotPassword_v0;
export const post_OperatorAuthController_forgotPassword_v0 = {
	method: z.literal('POST'),
	path: z.literal('/v0/operator/auth/forgot-password'),
	requestFormat: z.literal('json'),
	parameters: z.object({
		body: ForgotPasswordBodyDto,
	}),
	response: ForgotPasswordDto,
};

export type post_OperatorAuthController_resetPassword_v0 = typeof post_OperatorAuthController_resetPassword_v0;
export const post_OperatorAuthController_resetPassword_v0 = {
	method: z.literal('POST'),
	path: z.literal('/v0/operator/auth/reset-password'),
	requestFormat: z.literal('json'),
	parameters: z.object({
		body: ResetPasswordBodyDto,
	}),
	response: SuccessResponseDto,
};

export type post_OperatorAuthController_changePassword_v0 = typeof post_OperatorAuthController_changePassword_v0;
export const post_OperatorAuthController_changePassword_v0 = {
	method: z.literal('POST'),
	path: z.literal('/v0/operator/auth/change-password'),
	requestFormat: z.literal('json'),
	parameters: z.object({
		body: ChangePasswordDto,
	}),
	response: SuccessResponseDto,
};

export type get_OperatorAuthController_me_v0 = typeof get_OperatorAuthController_me_v0;
export const get_OperatorAuthController_me_v0 = {
	method: z.literal('GET'),
	path: z.literal('/v0/operator/auth/me'),
	requestFormat: z.literal('json'),
	parameters: z.never(),
	response: AuthOperatorDto,
};

export type patch_OperatorAuthController_updateMe_v0 = typeof patch_OperatorAuthController_updateMe_v0;
export const patch_OperatorAuthController_updateMe_v0 = {
	method: z.literal('PATCH'),
	path: z.literal('/v0/operator/auth/me'),
	requestFormat: z.literal('json'),
	parameters: z.object({
		body: UpdateOperatorBodyDto,
	}),
	response: AuthOperatorDto,
};

export type get_OperatorAuthController_invite_v0 = typeof get_OperatorAuthController_invite_v0;
export const get_OperatorAuthController_invite_v0 = {
	method: z.literal('GET'),
	path: z.literal('/v0/operator/auth/invitations/{inviteId}'),
	requestFormat: z.literal('json'),
	parameters: z.object({
		query: z.object({
			email: z.string(),
		}),
		path: z.object({
			inviteId: z.string(),
		}),
	}),
	response: FullOperatorInviteDto,
};

export type post_OperatorAuthController_acceptInvite_v0 = typeof post_OperatorAuthController_acceptInvite_v0;
export const post_OperatorAuthController_acceptInvite_v0 = {
	method: z.literal('POST'),
	path: z.literal('/v0/operator/auth/invitations/{inviteId}'),
	requestFormat: z.literal('json'),
	parameters: z.object({
		path: z.object({
			inviteId: z.string(),
		}),
	}),
	response: SuccessResponseDto,
};

export type get_OperatorUserController_searchUsers_v0 = typeof get_OperatorUserController_searchUsers_v0;
export const get_OperatorUserController_searchUsers_v0 = {
	method: z.literal('GET'),
	path: z.literal('/v0/operator/users/search'),
	requestFormat: z.literal('json'),
	parameters: z.object({
		query: z.object({
			query: z.string(),
		}),
	}),
	response: OperatorRewardsUserListDto,
};

export type get_OperatorUserController_getUserProfile_v0 = typeof get_OperatorUserController_getUserProfile_v0;
export const get_OperatorUserController_getUserProfile_v0 = {
	method: z.literal('GET'),
	path: z.literal('/v0/operator/user/{rewardsId}'),
	requestFormat: z.literal('json'),
	parameters: z.object({
		path: z.object({
			rewardsId: z.string(),
		}),
	}),
	response: OperatorRewardsUserProfileDto,
};

export type post_OperatorUserController_updateUser_v0 = typeof post_OperatorUserController_updateUser_v0;
export const post_OperatorUserController_updateUser_v0 = {
	method: z.literal('POST'),
	path: z.literal('/v0/operator/user/{rewardsId}'),
	requestFormat: z.literal('json'),
	parameters: z.object({
		path: z.object({
			rewardsId: z.string(),
		}),
		body: OperatorUpdateUserBodyDto,
	}),
	response: OperatorRewardsUserProfileDto,
};

export type post_OperatorUserController_claimGuestPasses_v0 = typeof post_OperatorUserController_claimGuestPasses_v0;
export const post_OperatorUserController_claimGuestPasses_v0 = {
	method: z.literal('POST'),
	path: z.literal('/v0/operator/user/{rewardsId}/claim-guest-passes'),
	requestFormat: z.literal('json'),
	parameters: z.object({
		path: z.object({
			rewardsId: z.string(),
		}),
		body: OperatorClaimPassesBodyDto,
	}),
	response: OperatorRewardsUserProfileDto,
};

export type patch_OperatorUserController_updateUserRewardLevel_v0 =
	typeof patch_OperatorUserController_updateUserRewardLevel_v0;
export const patch_OperatorUserController_updateUserRewardLevel_v0 = {
	method: z.literal('PATCH'),
	path: z.literal('/v0/operator/user/{rewardsId}/level'),
	requestFormat: z.literal('json'),
	parameters: z.object({
		path: z.object({
			rewardsId: z.string(),
		}),
		body: OperatorUpdateUserRewardLevelBodyDto,
	}),
	response: OperatorRewardsUserProfileDto,
};

export type post_OperatorUserController_addUserTransaction_v0 =
	typeof post_OperatorUserController_addUserTransaction_v0;
export const post_OperatorUserController_addUserTransaction_v0 = {
	method: z.literal('POST'),
	path: z.literal('/v0/operator/user/{rewardsId}/transactions'),
	requestFormat: z.literal('json'),
	parameters: z.object({
		path: z.object({
			rewardsId: z.string(),
		}),
		body: AddTransactionDto,
	}),
	response: UserRewardTransactionDto,
};

export type get_OperatorUserController_getUserTransactions_v0 =
	typeof get_OperatorUserController_getUserTransactions_v0;
export const get_OperatorUserController_getUserTransactions_v0 = {
	method: z.literal('GET'),
	path: z.literal('/v0/operator/user/{rewardsId}/transactions'),
	requestFormat: z.literal('json'),
	parameters: z.object({
		path: z.object({
			rewardsId: z.string(),
		}),
	}),
	response: UserRewardTransactionListDto,
};

export type patch_OperatorUserController_updateUserTransaction_v0 =
	typeof patch_OperatorUserController_updateUserTransaction_v0;
export const patch_OperatorUserController_updateUserTransaction_v0 = {
	method: z.literal('PATCH'),
	path: z.literal('/v0/operator/user/{rewardsId}/transactions/{transactionId}'),
	requestFormat: z.literal('json'),
	parameters: z.object({
		path: z.object({
			rewardsId: z.string(),
			transactionId: z.string(),
		}),
		body: UpdateTransactionDto,
	}),
	response: UserRewardTransactionDto,
};

export type delete_OperatorUserController_deleteUserTransaction_v0 =
	typeof delete_OperatorUserController_deleteUserTransaction_v0;
export const delete_OperatorUserController_deleteUserTransaction_v0 = {
	method: z.literal('DELETE'),
	path: z.literal('/v0/operator/user/{rewardsId}/transactions/{transactionId}'),
	requestFormat: z.literal('json'),
	parameters: z.object({
		path: z.object({
			rewardsId: z.string(),
			transactionId: z.string(),
		}),
	}),
	response: SuccessResponseDto,
};

export type post_OperatorUserController_redeemReward_v0 = typeof post_OperatorUserController_redeemReward_v0;
export const post_OperatorUserController_redeemReward_v0 = {
	method: z.literal('POST'),
	path: z.literal('/v0/operator/user/{rewardsId}/redemptions'),
	requestFormat: z.literal('json'),
	parameters: z.object({
		path: z.object({
			rewardsId: z.string(),
		}),
		body: OperatorRedeemRewardDto,
	}),
	response: SuccessResponseDto,
};

export type post_OperatorUserController_enableUser_v0 = typeof post_OperatorUserController_enableUser_v0;
export const post_OperatorUserController_enableUser_v0 = {
	method: z.literal('POST'),
	path: z.literal('/v0/operator/user/{rewardsId}/enable'),
	requestFormat: z.literal('json'),
	parameters: z.object({
		path: z.object({
			rewardsId: z.string(),
		}),
	}),
	response: OperatorRewardsUserProfileDto,
};

export type post_OperatorUserController_disableUser_v0 = typeof post_OperatorUserController_disableUser_v0;
export const post_OperatorUserController_disableUser_v0 = {
	method: z.literal('POST'),
	path: z.literal('/v0/operator/user/{rewardsId}/disable'),
	requestFormat: z.literal('json'),
	parameters: z.object({
		path: z.object({
			rewardsId: z.string(),
		}),
	}),
	response: SuccessResponseDto,
};

export type get_OperatorFacilityController_getFacility_v0 = typeof get_OperatorFacilityController_getFacility_v0;
export const get_OperatorFacilityController_getFacility_v0 = {
	method: z.literal('GET'),
	path: z.literal('/v0/operator/facilities/{facilityId}'),
	requestFormat: z.literal('json'),
	parameters: z.object({
		path: z.object({
			facilityId: z.string(),
		}),
	}),
	response: OperatorFacilityAndRoleDto,
};

export type post_OperatorFacilityController_signupUser_v0 = typeof post_OperatorFacilityController_signupUser_v0;
export const post_OperatorFacilityController_signupUser_v0 = {
	method: z.literal('POST'),
	path: z.literal('/v0/operator/facilities/{facilityId}/user/signup'),
	requestFormat: z.literal('json'),
	parameters: z.object({
		path: z.object({
			facilityId: z.string(),
		}),
		body: OperatorSignupUserDto,
	}),
	response: OperatorRewardsUserDto,
};

export type get_OperatorFacilityController_getMembers_v0 = typeof get_OperatorFacilityController_getMembers_v0;
export const get_OperatorFacilityController_getMembers_v0 = {
	method: z.literal('GET'),
	path: z.literal('/v0/operator/facilities/{facilityId}/members'),
	requestFormat: z.literal('json'),
	parameters: z.object({
		path: z.object({
			facilityId: z.string(),
		}),
	}),
	response: OperatorMembershipListWithInvitesDto,
};

export type post_OperatorFacilityController_inviteMember_v0 = typeof post_OperatorFacilityController_inviteMember_v0;
export const post_OperatorFacilityController_inviteMember_v0 = {
	method: z.literal('POST'),
	path: z.literal('/v0/operator/facilities/{facilityId}/members/invitations'),
	requestFormat: z.literal('json'),
	parameters: z.object({
		path: z.object({
			facilityId: z.string(),
		}),
		body: OperatorInviteMemberBodyDto,
	}),
	response: OperatorInviteDto,
};

export type patch_OperatorFacilityController_resendInvite_v0 = typeof patch_OperatorFacilityController_resendInvite_v0;
export const patch_OperatorFacilityController_resendInvite_v0 = {
	method: z.literal('PATCH'),
	path: z.literal('/v0/operator/facilities/{facilityId}/members/invitations/{inviteId}'),
	requestFormat: z.literal('json'),
	parameters: z.object({
		path: z.object({
			inviteId: z.string(),
		}),
	}),
	response: SuccessResponseDto,
};

export type delete_OperatorFacilityController_cancelInvite_v0 =
	typeof delete_OperatorFacilityController_cancelInvite_v0;
export const delete_OperatorFacilityController_cancelInvite_v0 = {
	method: z.literal('DELETE'),
	path: z.literal('/v0/operator/facilities/{facilityId}/members/invitations/{inviteId}'),
	requestFormat: z.literal('json'),
	parameters: z.object({
		path: z.object({
			inviteId: z.string(),
		}),
	}),
	response: SuccessResponseDto,
};

export type patch_OperatorFacilityController_updateMember_v0 = typeof patch_OperatorFacilityController_updateMember_v0;
export const patch_OperatorFacilityController_updateMember_v0 = {
	method: z.literal('PATCH'),
	path: z.literal('/v0/operator/facilities/{facilityId}/members/{memberId}'),
	requestFormat: z.literal('json'),
	parameters: z.object({
		path: z.object({
			facilityId: z.string(),
			memberId: z.string(),
		}),
		body: OperatorUpdateMemberBodyDto,
	}),
	response: OperatorFacilityMembershipDto,
};

export type delete_OperatorFacilityController_removeMember_v0 =
	typeof delete_OperatorFacilityController_removeMember_v0;
export const delete_OperatorFacilityController_removeMember_v0 = {
	method: z.literal('DELETE'),
	path: z.literal('/v0/operator/facilities/{facilityId}/members/{memberId}'),
	requestFormat: z.literal('json'),
	parameters: z.object({
		path: z.object({
			facilityId: z.string(),
			memberId: z.string(),
		}),
	}),
	response: SuccessResponseDto,
};

export type get_OperatorFacilityController_getRedemptionOptions_v0 =
	typeof get_OperatorFacilityController_getRedemptionOptions_v0;
export const get_OperatorFacilityController_getRedemptionOptions_v0 = {
	method: z.literal('GET'),
	path: z.literal('/v0/operator/facilities/{facilityId}/rewards'),
	requestFormat: z.literal('json'),
	parameters: z.object({
		path: z.object({
			facilityId: z.string(),
		}),
	}),
	response: OperatorFacilityRedemptionRewardsListDto,
};

export type get_OperatorFacilityController_getReservations_v0 =
	typeof get_OperatorFacilityController_getReservations_v0;
export const get_OperatorFacilityController_getReservations_v0 = {
	method: z.literal('GET'),
	path: z.literal('/v0/operator/facilities/{facilityId}/reservations'),
	requestFormat: z.literal('json'),
	parameters: z.object({
		query: z.object({
			date: z.string(),
		}),
		path: z.object({
			facilityId: z.string(),
		}),
	}),
	response: OperatorReservationListDto,
};

export type get_OperatorFacilityController_getSignupSummaryStats_v0 =
	typeof get_OperatorFacilityController_getSignupSummaryStats_v0;
export const get_OperatorFacilityController_getSignupSummaryStats_v0 = {
	method: z.literal('GET'),
	path: z.literal('/v0/operator/facilities/{facilityId}/signup-stats'),
	requestFormat: z.literal('json'),
	parameters: z.object({
		path: z.object({
			facilityId: z.string(),
		}),
	}),
	response: SignupStatsResponseDto,
};

export type get_OperatorFacilityController_getRewardsSummaryStats_v0 =
	typeof get_OperatorFacilityController_getRewardsSummaryStats_v0;
export const get_OperatorFacilityController_getRewardsSummaryStats_v0 = {
	method: z.literal('GET'),
	path: z.literal('/v0/operator/facilities/{facilityId}/rewards-stats'),
	requestFormat: z.literal('json'),
	parameters: z.object({
		path: z.object({
			facilityId: z.string(),
		}),
	}),
	response: RewardsStatsResponseDto,
};

export type post_OperatorFacilityController_startUploadMedia_v0 =
	typeof post_OperatorFacilityController_startUploadMedia_v0;
export const post_OperatorFacilityController_startUploadMedia_v0 = {
	method: z.literal('POST'),
	path: z.literal('/v0/operator/facilities/{facilityId}/media'),
	requestFormat: z.literal('json'),
	parameters: z.object({
		path: z.object({
			facilityId: z.string(),
		}),
		body: StartUploadMediaBodyDto,
	}),
	response: StartUploadMediaResponseDto,
};

export type get_OperatorFacilityController_getMedia_v0 = typeof get_OperatorFacilityController_getMedia_v0;
export const get_OperatorFacilityController_getMedia_v0 = {
	method: z.literal('GET'),
	path: z.literal('/v0/operator/facilities/{facilityId}/media'),
	requestFormat: z.literal('json'),
	parameters: z.object({
		path: z.object({
			facilityId: z.string(),
		}),
	}),
	response: FacilityMediaDto,
};

export type put_OperatorFacilityController_finalizeUploadMedia_v0 =
	typeof put_OperatorFacilityController_finalizeUploadMedia_v0;
export const put_OperatorFacilityController_finalizeUploadMedia_v0 = {
	method: z.literal('PUT'),
	path: z.literal('/v0/operator/facilities/{facilityId}/media/{mediaId}'),
	requestFormat: z.literal('json'),
	parameters: z.object({
		path: z.object({
			facilityId: z.string(),
			mediaId: z.string(),
		}),
	}),
	response: MediaDto,
};

export type delete_OperatorFacilityController_deleteMedia_v0 = typeof delete_OperatorFacilityController_deleteMedia_v0;
export const delete_OperatorFacilityController_deleteMedia_v0 = {
	method: z.literal('DELETE'),
	path: z.literal('/v0/operator/facilities/{facilityId}/media/{mediaId}'),
	requestFormat: z.literal('json'),
	parameters: z.object({
		path: z.object({
			facilityId: z.string(),
			mediaId: z.string(),
		}),
	}),
	response: SuccessResponseDto,
};

export type get_OperatorFacilitiesController_getAllFacilities_v0 =
	typeof get_OperatorFacilitiesController_getAllFacilities_v0;
export const get_OperatorFacilitiesController_getAllFacilities_v0 = {
	method: z.literal('GET'),
	path: z.literal('/v0/operator/facilities'),
	requestFormat: z.literal('json'),
	parameters: z.never(),
	response: OperatorMembershipFacilityListDto,
};

export type get_TroonAccessController_getPaymentMethods_v0 = typeof get_TroonAccessController_getPaymentMethods_v0;
export const get_TroonAccessController_getPaymentMethods_v0 = {
	method: z.literal('GET'),
	path: z.literal('/v0/troon-access/payment-methods'),
	requestFormat: z.literal('json'),
	parameters: z.never(),
	response: StripePaymentMethodsDto,
};

export type post_TroonAccessController_addPaymentMethod_v0 = typeof post_TroonAccessController_addPaymentMethod_v0;
export const post_TroonAccessController_addPaymentMethod_v0 = {
	method: z.literal('POST'),
	path: z.literal('/v0/troon-access/payment-methods'),
	requestFormat: z.literal('json'),
	parameters: z.object({
		body: AddPaymentMethodBodyDto,
	}),
	response: AddPaymentMethodResponseDto,
};

export type get_ProgramsController_getSignupProgram_v0 = typeof get_ProgramsController_getSignupProgram_v0;
export const get_ProgramsController_getSignupProgram_v0 = {
	method: z.literal('GET'),
	path: z.literal('/v0/rewards/programs/{programSignUpType}'),
	requestFormat: z.literal('json'),
	parameters: z.object({
		path: z.object({
			programSignUpType: z.union([
				z.literal('VILLAGE_GAINEY'),
				z.literal('VILLAGE_CAMELBACK'),
				z.literal('VILLAGE_DC_RANCH'),
				z.literal('VILLAGE_OCOTILLO'),
			]),
		}),
	}),
	response: ProgramSignupInfoResponseDto,
};

export type post_ProgramsController_enrollSignupProgram_v0 = typeof post_ProgramsController_enrollSignupProgram_v0;
export const post_ProgramsController_enrollSignupProgram_v0 = {
	method: z.literal('POST'),
	path: z.literal('/v0/rewards/programs/{programSignUpType}'),
	requestFormat: z.literal('json'),
	parameters: z.object({
		path: z.object({
			programSignUpType: z.union([
				z.literal('VILLAGE_GAINEY'),
				z.literal('VILLAGE_CAMELBACK'),
				z.literal('VILLAGE_DC_RANCH'),
				z.literal('VILLAGE_OCOTILLO'),
			]),
		}),
	}),
	response: ProgramSignupEnrollResponseDto,
};

export type get_VisaController_getVisaStatus_v0 = typeof get_VisaController_getVisaStatus_v0;
export const get_VisaController_getVisaStatus_v0 = {
	method: z.literal('GET'),
	path: z.literal('/v0/visa/status'),
	requestFormat: z.literal('json'),
	parameters: z.never(),
	response: VisaStatusResponseDto,
};

export type get_VisaController_verifyVisaRequest_v0 = typeof get_VisaController_verifyVisaRequest_v0;
export const get_VisaController_verifyVisaRequest_v0 = {
	method: z.literal('GET'),
	path: z.literal('/v0/visa/tokens/{token}'),
	requestFormat: z.literal('json'),
	parameters: z.object({
		path: z.object({
			token: z.string(),
		}),
	}),
	response: VisaTokenResultDto,
};

export type post_VisaController_getVisaToken_v0 = typeof post_VisaController_getVisaToken_v0;
export const post_VisaController_getVisaToken_v0 = {
	method: z.literal('POST'),
	path: z.literal('/v0/visa/tokens'),
	requestFormat: z.literal('json'),
	parameters: z.never(),
	response: VisaTokenDto,
};

export type get_VisaController_getPriveRequests_v0 = typeof get_VisaController_getPriveRequests_v0;
export const get_VisaController_getPriveRequests_v0 = {
	method: z.literal('GET'),
	path: z.literal('/v0/visa/prive/requests'),
	requestFormat: z.literal('json'),
	parameters: z.never(),
	response: PriveTeeTimeRequestDto,
};

export type post_VisaController_createVisaPriveRequest_v0 = typeof post_VisaController_createVisaPriveRequest_v0;
export const post_VisaController_createVisaPriveRequest_v0 = {
	method: z.literal('POST'),
	path: z.literal('/v0/visa/prive/requests'),
	requestFormat: z.literal('json'),
	parameters: z.object({
		body: PriveRequestBodyDto,
	}),
	response: PriveTeeTimeRequestDto,
};

export type post_UnlimitedGuestPassController_previewUpgrade_v0 =
	typeof post_UnlimitedGuestPassController_previewUpgrade_v0;
export const post_UnlimitedGuestPassController_previewUpgrade_v0 = {
	method: z.literal('POST'),
	path: z.literal('/v0/unlimited-guest-pass/preview'),
	requestFormat: z.literal('json'),
	parameters: z.object({
		body: UnlimitedGuestPassPreviewBodyDto,
	}),
	response: UnlimitedGuestPassPreviewResponseDto,
};

export type post_UnlimitedGuestPassController_upgrade_v0 = typeof post_UnlimitedGuestPassController_upgrade_v0;
export const post_UnlimitedGuestPassController_upgrade_v0 = {
	method: z.literal('POST'),
	path: z.literal('/v0/unlimited-guest-pass/upgrade'),
	requestFormat: z.literal('json'),
	parameters: z.object({
		body: UnlimitedGuestPassUpgradeBodyDto,
	}),
	response: UnlimitedGuestPassUpgradeResponseDto,
};

// <EndpointByMethod>
export const EndpointByMethod = {
	post: {
		'/v0/operator/auth/signup': post_OperatorAuthController_signup_v0,
		'/v0/operator/auth/login': post_OperatorAuthController_login_v0,
		'/v0/operator/auth/logout': post_OperatorAuthController_logout_v0,
		'/v0/operator/auth/forgot-password': post_OperatorAuthController_forgotPassword_v0,
		'/v0/operator/auth/reset-password': post_OperatorAuthController_resetPassword_v0,
		'/v0/operator/auth/change-password': post_OperatorAuthController_changePassword_v0,
		'/v0/operator/auth/invitations/{inviteId}': post_OperatorAuthController_acceptInvite_v0,
		'/v0/operator/user/{rewardsId}': post_OperatorUserController_updateUser_v0,
		'/v0/operator/user/{rewardsId}/claim-guest-passes': post_OperatorUserController_claimGuestPasses_v0,
		'/v0/operator/user/{rewardsId}/transactions': post_OperatorUserController_addUserTransaction_v0,
		'/v0/operator/user/{rewardsId}/redemptions': post_OperatorUserController_redeemReward_v0,
		'/v0/operator/user/{rewardsId}/enable': post_OperatorUserController_enableUser_v0,
		'/v0/operator/user/{rewardsId}/disable': post_OperatorUserController_disableUser_v0,
		'/v0/operator/facilities/{facilityId}/user/signup': post_OperatorFacilityController_signupUser_v0,
		'/v0/operator/facilities/{facilityId}/members/invitations': post_OperatorFacilityController_inviteMember_v0,
		'/v0/operator/facilities/{facilityId}/media': post_OperatorFacilityController_startUploadMedia_v0,
		'/v0/troon-access/payment-methods': post_TroonAccessController_addPaymentMethod_v0,
		'/v0/rewards/programs/{programSignUpType}': post_ProgramsController_enrollSignupProgram_v0,
		'/v0/visa/tokens': post_VisaController_getVisaToken_v0,
		'/v0/visa/prive/requests': post_VisaController_createVisaPriveRequest_v0,
		'/v0/unlimited-guest-pass/preview': post_UnlimitedGuestPassController_previewUpgrade_v0,
		'/v0/unlimited-guest-pass/upgrade': post_UnlimitedGuestPassController_upgrade_v0,
	},
	get: {
		'/v0/operator/auth/me': get_OperatorAuthController_me_v0,
		'/v0/operator/auth/invitations/{inviteId}': get_OperatorAuthController_invite_v0,
		'/v0/operator/users/search': get_OperatorUserController_searchUsers_v0,
		'/v0/operator/user/{rewardsId}': get_OperatorUserController_getUserProfile_v0,
		'/v0/operator/user/{rewardsId}/transactions': get_OperatorUserController_getUserTransactions_v0,
		'/v0/operator/facilities/{facilityId}': get_OperatorFacilityController_getFacility_v0,
		'/v0/operator/facilities/{facilityId}/members': get_OperatorFacilityController_getMembers_v0,
		'/v0/operator/facilities/{facilityId}/rewards': get_OperatorFacilityController_getRedemptionOptions_v0,
		'/v0/operator/facilities/{facilityId}/reservations': get_OperatorFacilityController_getReservations_v0,
		'/v0/operator/facilities/{facilityId}/signup-stats': get_OperatorFacilityController_getSignupSummaryStats_v0,
		'/v0/operator/facilities/{facilityId}/rewards-stats': get_OperatorFacilityController_getRewardsSummaryStats_v0,
		'/v0/operator/facilities/{facilityId}/media': get_OperatorFacilityController_getMedia_v0,
		'/v0/operator/facilities': get_OperatorFacilitiesController_getAllFacilities_v0,
		'/v0/troon-access/payment-methods': get_TroonAccessController_getPaymentMethods_v0,
		'/v0/rewards/programs/{programSignUpType}': get_ProgramsController_getSignupProgram_v0,
		'/v0/visa/status': get_VisaController_getVisaStatus_v0,
		'/v0/visa/tokens/{token}': get_VisaController_verifyVisaRequest_v0,
		'/v0/visa/prive/requests': get_VisaController_getPriveRequests_v0,
	},
	patch: {
		'/v0/operator/auth/me': patch_OperatorAuthController_updateMe_v0,
		'/v0/operator/user/{rewardsId}/level': patch_OperatorUserController_updateUserRewardLevel_v0,
		'/v0/operator/user/{rewardsId}/transactions/{transactionId}': patch_OperatorUserController_updateUserTransaction_v0,
		'/v0/operator/facilities/{facilityId}/members/invitations/{inviteId}':
			patch_OperatorFacilityController_resendInvite_v0,
		'/v0/operator/facilities/{facilityId}/members/{memberId}': patch_OperatorFacilityController_updateMember_v0,
	},
	delete: {
		'/v0/operator/user/{rewardsId}/transactions/{transactionId}':
			delete_OperatorUserController_deleteUserTransaction_v0,
		'/v0/operator/facilities/{facilityId}/members/invitations/{inviteId}':
			delete_OperatorFacilityController_cancelInvite_v0,
		'/v0/operator/facilities/{facilityId}/members/{memberId}': delete_OperatorFacilityController_removeMember_v0,
		'/v0/operator/facilities/{facilityId}/media/{mediaId}': delete_OperatorFacilityController_deleteMedia_v0,
	},
	put: {
		'/v0/operator/facilities/{facilityId}/media/{mediaId}': put_OperatorFacilityController_finalizeUploadMedia_v0,
	},
};
export type EndpointByMethod = typeof EndpointByMethod;
// </EndpointByMethod>

// <EndpointByMethod.Shorthands>
export type PostEndpoints = EndpointByMethod['post'];
export type GetEndpoints = EndpointByMethod['get'];
export type PatchEndpoints = EndpointByMethod['patch'];
export type DeleteEndpoints = EndpointByMethod['delete'];
export type PutEndpoints = EndpointByMethod['put'];
export type AllEndpoints = EndpointByMethod[keyof EndpointByMethod];
// </EndpointByMethod.Shorthands>

// <ApiClientTypes>
export type EndpointParameters = {
	body?: unknown;
	query?: Record<string, unknown>;
	header?: Record<string, unknown>;
	path?: Record<string, unknown>;
};

export type MutationMethod = 'post' | 'put' | 'patch' | 'delete';
export type Method = 'get' | 'head' | 'options' | MutationMethod;

type RequestFormat = 'json' | 'form-data' | 'form-url' | 'binary' | 'text';

export type DefaultEndpoint = {
	parameters?: EndpointParameters | undefined;
	response: unknown;
};

export type Endpoint<TConfig extends DefaultEndpoint = DefaultEndpoint> = {
	operationId: string;
	method: Method;
	path: string;
	requestFormat: RequestFormat;
	parameters?: TConfig['parameters'];
	meta: {
		alias: string;
		hasParameters: boolean;
		areParametersRequired: boolean;
	};
	response: TConfig['response'];
};
