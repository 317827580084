import type { components } from './__generated__/database';

export class ApiError extends Error {
	error: IError;
	statusCode: number;

	constructor(statusCode: number, error: IError) {
		super(error.displayMessage, { cause: error });
		this.statusCode = statusCode;
		this.error = error;
	}
}

export class UnknownApiError extends ApiError {
	constructor(error: Partial<IError> = {}) {
		super(500, { displayMessage: 'An unknown error occurred', ...error });
	}
}

export type IError = components['schemas']['HTTPErrorDto'];
